import { Fragment } from 'react';
import KombuchaSummary from '../KombuchaSummary';
import Panel from '../../UI/Panel';
import Card from '../../UI/Card';

import scobyImg from '../../../assets/scoby.png'

import classes from './Kombucha.module.css';

const Kombucha = (props) => {
    return (
        <Fragment>
            <KombuchaSummary />
            <Panel>
                <Card>
                    <h1>O que é Kombucha?</h1>
                    Kombucha é uma bebida fermentada à base de chá adoçado que tem sido consumida há séculos em muitas partes do mundo. A fermentação é realizada por uma colônia de bactérias e leveduras conhecida como "SCOBY" (sigla em inglês para Symbiotic Culture of Bacteria and Yeast) que consome o açúcar do chá e produz ácido acético, ácido láctico, gás carbônico e uma pequena quantidade de álcool.
                    <br /><br />
                    <img src={scobyImg} alt='SCOBY' title='SCOBY'/>
                    <br /><br />

                    Os benefícios do kombucha são atribuídos à sua composição nutricional e aos produtos metabólicos produzidos durante a fermentação. Alguns dos benefícios relatados incluem:
                    <br /><br />
                    <ul>
                        <li>
                            <strong>Melhora da digestão</strong>: A fermentação produz ácido acético e outros ácidos orgânicos que ajudam na digestão e podem melhorar a absorção de nutrientes.
                        </li>
                        <li>
                            <strong>Ação detox</strong>: O kombucha pode ajudar a eliminar as toxinas do organismo, especialmente quando consumido regularmente.
                        </li>
                        <li>
                            <strong>Fortalecimento do sistema imunológico</strong>: Algumas pesquisas indicam que o kombucha pode estimular o sistema imunológico e ajudar a prevenir infecções.
                        </li>
                        <li>
                            <strong>Controle do açúcar no sangue</strong>: Estudos preliminares sugerem que o kombucha pode ajudar a controlar o açúcar no sangue, o que pode ser benéfico para pessoas com diabetes.
                        </li>
                        <li>
                            <strong>Redução do risco de doenças cardíacas</strong>: Alguns estudos indicam que o kombucha pode ajudar a reduzir o colesterol LDL (ruim) e triglicerídeos, o que pode reduzir o risco de doenças cardíacas.
                        </li>
                    </ul>

                    <p>
                        No entanto, é importante notar que a maioria dos estudos sobre o kombucha foram realizados em animais ou em laboratório e são necessárias mais pesquisas em humanos para confirmar esses benefícios. Além disso, o kombucha contém uma pequena quantidade de álcool, então é importante consumi-lo com moderação.
                    </p>

                    <h1>Kombucha x Refrigerantes</h1>
                    <p>
                        A quantidade de açúcar nos refrigerantes e na kombucha pode variar de acordo com a marca e o sabor específico do produto. No entanto, em geral, os refrigerantes têm uma quantidade significativa de açúcar adicionado, enquanto a kombucha é geralmente feita a partir da fermentação de chá adoçado, o que resulta em um teor de açúcar menor em comparação aos refrigerantes.
                    </p>
                    <p>
                        Para dar uma ideia geral, 350 ml de refrigerante de pode conter cerca de 40 gramas de açúcar adicionado, o que é mais do que a quantidade diária recomendada de açúcar para um adulto. Por outro lado, 330 ml de kombucha pode ter cerca de 5 gramas de açúcar naturalmente presente no chá adoçado, que é consumido durante o processo de fermentação. No entanto, é importante notar que algumas marcas de kombucha podem adicionar açúcar extra após a fermentação para melhorar o sabor, o que aumentaria o teor de açúcar.<br /><br />

                        <table className={classes.table}>
                            <tr>
                                <th>Bebida</th>
                                <th>Quantidade Aproximada<br/>de Açúcar (em 600ml)</th>
                            </tr>
                            <tr>
                                <td>Refrigerante de Noz de Cola</td>
                                <td>63g</td>
                            </tr>
                            <tr>
                                <td>Refrigerante de Limão</td>
                                <td>69g</td>
                            </tr>
                            <tr>
                                <td>Refrigerante de Guaraná</td>
                                <td>63g</td>
                            </tr>
                            <tr>
                                <td>Cerveja Pilsen</td>
                                <td>36g</td>
                            </tr>
                            <tr>
                                <td>Cerveja Malzbier</td>
                                <td>72g</td>
                            </tr>                            
                        </table>
                    </p>
                    Portanto, é sempre importante verificar os rótulos dos produtos específicos para saber a quantidade exata de açúcar presente. Além disso, é recomendado limitar a ingestão de açúcar adicionado em geral para manter uma dieta saudável.
                </Card>
            </Panel>
        </Fragment>
    );
};

export default Kombucha;



