import { Fragment } from 'react';

import HeaderCartButton from './HeaderCartButton';
import HeaderProductListButton from './HeaderProductListButton';
import HeaderKombuchaButton from './HeaderKombuchaButton';
import kombuchaText from '../../assets/psykombucha-text.png';
import kombuchaImage from '../../assets/bg04.png';

import classes from './Header.module.css';
import { Navbar, Nav, Container} from 'react-bootstrap';

const Header = (props) => {
  return (
    <Fragment>
      <header className={classes.header}>
        <Navbar expand="lg">
          <Container>
            <Navbar.Brand><img src={kombuchaText} alt='PSYKOMBUCHA' /></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link><HeaderKombuchaButton onClick={props.onShowKombucha} /></Nav.Link>
                <Nav.Link><HeaderProductListButton onClick={props.onShowProducts} /></Nav.Link>
                <Nav.Link><HeaderCartButton onClick={props.onShowCart} /></Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </header>

      <div className={classes['main-image']}>
        <img src={kombuchaImage} alt='A table full of delicious food!' />
      </div>
    </Fragment>
  );
};

export default Header;
