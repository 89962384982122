import { useState } from 'react';

import Header from './components/Layout/Header';
import Meals from './components/Meals/Meals';
import Cart from './components/Cart/Cart';
import CartProvider from './store/CartProvider';
import Kombucha from './components/Content/Kombucha/Kombucha';

function App() {
  const [kombuchaIsShown, setKombuchaIsShown] = useState(false);
  const [cartIsShown, setCartIsShown] = useState(false);

  const showCartHandler = () => {
    setCartIsShown(true);
  };

  const showKombuchaHandler = () => {
    setKombuchaIsShown(true);
  };

  const showProductsHandler = () => {
    setKombuchaIsShown(false);
  };

  const hideCartHandler = () => {
    setCartIsShown(false);
  };

  const hideKombuchaHandler = () => {
    setKombuchaIsShown(false);
  };

  return (
    <CartProvider>
      {cartIsShown && <Cart onClose={hideCartHandler} />}
      <Header onShowCart={showCartHandler} onShowKombucha={showKombuchaHandler} onShowProducts={showProductsHandler}/>
      <main>
        {!kombuchaIsShown && <Meals />}
        {kombuchaIsShown && <Kombucha onClose={hideKombuchaHandler} />}
      </main>
    </CartProvider>
  );
}

export default App;
