import { useEffect, useState } from 'react';

import ListIcon from '../Content/Kombucha/ListIcon';
import classes from './HeaderProductListButton.module.css';

const HeaderProductListButton = (props) => {
  const [btnIsHighlighted, setBtnIsHighlighted] = useState(false);

  const btnClasses = `${classes.button} ${btnIsHighlighted ? classes.bump : ''}`;

  useEffect(() => {
    setBtnIsHighlighted(true);

    const timer = setTimeout(() => {
      setBtnIsHighlighted(false);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  return (
    <button className={btnClasses} onClick={props.onClick}>
      <span className={classes.icon}>
        <ListIcon />
      </span>
      <span>PRODUTOS</span>
    </button>
  );
};

export default HeaderProductListButton;
