import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCartPlus } from '@fortawesome/free-solid-svg-icons'

const CartPlusIcon = () => {
  return (
    <FontAwesomeIcon icon={faCartPlus} />
  );
};

export default CartPlusIcon;
