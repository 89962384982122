import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faWineBottle } from '@fortawesome/free-solid-svg-icons'

const KombuchaIcon = () => {
  return (
    <FontAwesomeIcon icon={faWineBottle} />
  );
};

export default KombuchaIcon;
