import { Fragment } from 'react';

import KombuchaSummary from './../Content/KombuchaSummary';
import AvailableMeals from './AvailableMeals';

const Meals = () => {
  return (
    <Fragment>
      <KombuchaSummary />
      <AvailableMeals />
    </Fragment>
  );
};

export default Meals;
