import { useRef, useState } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';

import classes from './Checkout.module.css';

const isEmpty = value => value.trim() === '';
const isFiveChars = value => value.trim().length === 5;

const Checkout = (props) => {
  const [formInputsValidity, setFormInputsValidity] = useState({
    name: true,
    street: true,
    city: true,
    postalCode: true
  });

  const [item, setItem] = useState({ kindOfStand: "loja", another: "another" });
  const { kindOfStand } = item;
  const showAddress = kindOfStand !== "loja";

  const handleChange = e => {
    e.persist();
    console.log(e.target.value);

    setItem(prevState => ({
      ...prevState,
      kindOfStand: e.target.value
    }));
  };

  const nameInputRef = useRef();
  const streetInputRef = useRef();
  const postalCodeInputRef = useRef();
  const cityInputRef = useRef();

  const confirmHandler = (event) => {
    event.preventDefault();

    const enteredName = nameInputRef.current.value;
    const enteredStreet = streetInputRef.current.value;
    const enteredPostalCode = postalCodeInputRef.current.value;
    const enteredCity = cityInputRef.current.value;

    const enteredNameIsValid = !isEmpty(enteredName);
    const enteredStreetIsValid = !isEmpty(enteredStreet);
    const enteredCityIsValid = !isEmpty(enteredCity);
    const enteredPostalCodeIsValid = isFiveChars(enteredPostalCode);

    setFormInputsValidity({
      name: enteredNameIsValid,
      street: enteredStreetIsValid,
      city: enteredCityIsValid,
      postalCode: enteredPostalCodeIsValid
    });

    const formIsValid = enteredNameIsValid && enteredStreetIsValid && enteredCityIsValid && enteredPostalCodeIsValid;

    if (!formIsValid) {
      return;
    }

    props.onConfirm({
      name: enteredName,
      street: enteredStreet,
      city: enteredCity,
      postalCode: enteredPostalCode
    });
  };

  const nameControlClasses = `${classes.control} ${formInputsValidity.name ? '' : classes.invalid}`;
  const streetControlClasses = `${classes.control} ${formInputsValidity.street ? '' : classes.invalid}`;
  const postalCodeControlClasses = `${classes.control} ${formInputsValidity.postalCode ? '' : classes.invalid}`;
  const cityControlClasses = `${classes.control} ${formInputsValidity.city ? '' : classes.invalid}`;

  return (
    <form className={classes.form} onSubmit={confirmHandler}>
      <Container>
        <Row>
          <Col>Preencha o formulário para completar o pedido!</Col>
        </Row><br />
        <Row>
          <Col xs={6}>
            <div className={nameControlClasses}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Cidade:</Form.Label>
                <Form.Control type="text" placeholder="Normal text" />
              </Form.Group>
            </div>
          </Col>
          <Col xs={6}>
            <div className={cityControlClasses}>
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Label>Cidade:</Form.Label>
                <Form.Control as="select">
                  <option>Mariana</option>
                  <option>Ouro Preto</option>
                </Form.Control>
              </Form.Group>
              {!formInputsValidity.city && <p>Por favor, insira uma cidade válida!</p>}
            </div>
          </Col>

          <Col xs={6}>
            <div className={streetControlClasses}>
              <Form.Group controlId="formBasicEmail">
                <Form.Label>E-mail:</Form.Label>
                <Form.Control type="email" placeholder="" />
                {/* <Form.Text className="text-muted">
                  We'll never share your email with anyone else.
                </Form.Text> */}
              </Form.Group>
              {!formInputsValidity.street && <p>Por favor, insira um endereço válido!</p>}
            </div>
          </Col>
          <Col xs={6}>
            <Form.Group controlId="kindOfStand">
              <Form.Label><b>Envio:</b></Form.Label>
              <Form.Check
                value="endereco"
                type="radio"
                aria-label="radio 1"
                label="Quero receber em um endereço*"
                onChange={handleChange}
                checked={kindOfStand === "endereco"}
              />
              <Form.Check
                value="loja"
                type="radio"
                aria-label="radio 2"
                label="Vou retirar na loja"
                onChange={handleChange}
                checked={kindOfStand === "loja"}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col>
            {showAddress &&
              <div className={streetControlClasses}>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Endereço:</Form.Label>
                  <Form.Control as="textarea" rows={3} />
                </Form.Group>
              </div>
            }
            *<small>O valor da compra será acrescido do valor da entrega</small>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className={classes.actions}>
              <button type='button' onClick={props.onCancel}>
                Cancelar
              </button>
              <button className={classes.submit}>Confirmar</button>
            </div>
          </Col>
        </Row>
      </Container>
    </form >
  );
};

export default Checkout;
