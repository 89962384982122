import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList } from '@fortawesome/free-solid-svg-icons'

const ListIcon = () => {
  return (
    <FontAwesomeIcon icon={faList} />
  );
};

export default ListIcon;
