import classes from '../UI/Summary.module.css';

import psykombuchaLogo from '../../assets/psykombucha-256.png'

const KombuchaSummary = () => {
  return (
    <section className={classes.summary}>
      <img src={psykombuchaLogo} alt='Ururu!' title='Ururu!'/>
      <h4>Bebida saudável feita artesanalmente com carinho!</h4>
    </section>
  );
};

export default KombuchaSummary;
